import axios from 'axios';
import { useState, useEffect } from 'react';
//import { useNavigate } from 'react-router-dom';


export default function AuthUser() {

    //const navigate = useNavigate();  
    const [token, setToken] = useState(getToken());
    const [user, setUser] = useState(getUser());
    const [missao, setMissao] = useState(getMissao());
    const [missoes, setMissoes] = useState(getMissoes());
    const [empresa_selecionada, setEmpresa] = useState(getEmpresa());
    const [logoTipo, setLogoTipo] = useState();
    const [user_permissoes, setPermissoes] = useState([]);
    const [multipla_visibilidade, setMultiplaVisibilidade] = useState(getMultiplaVisibilidade());

    const url_root = "https://formacao-api.equalizador.ao";
    //const url_root = "https://projetos-artefe.excelproexpert.ao";
    //const url_root = "http://192.168.1.200:8000";
    //const url_root = "http://127.0.0.1:8000";
    //const url_root = "https://admin.excelproexpert.ao";
    //const url_root = "https://trainningbusiness.kahewa.ao";


    //This is case setUser depends on token value so
    //it makes that assincronous wait for the value of token

    useEffect(() => {
        setLogoTipo('/static/media/logo-white.6d4161d9bceda0741c4e.png');
        setUser(getUser());
        setMultiplaVisibilidade(getMultiplaVisibilidade());
        setEmpresa(getEmpresa());
        setPermissoes(getPermissoes());
        setMissao(getMissao());
        setMissoes(getMissoes());
    }, [token]);

    function getToken() {
        const tokenString = sessionStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        return userToken;
    }

    function getUser() {
        const userString = sessionStorage.getItem('user');
        const userDetail = JSON.parse(userString);
        return userDetail || null; // Set to null if userDetail is false
    }

    function getMissao() {
        const missaoString = sessionStorage.getItem('missao');
        const missaoDetail = JSON.parse(missaoString);
        return missaoDetail || null;
    }

    function getMissoes() {
        const missoesString = sessionStorage.getItem('missoes');
        const missoesDetail = JSON.parse(missoesString);
        return missoesDetail || null;
    }

    function getEmpresa() {
        const empresaString = sessionStorage.getItem('empresa');
        const empresaDetail = JSON.parse(empresaString);
        return empresaDetail || null; // Set to null if userDetail is false
    }

    function getMultiplaVisibilidade() {
        const visibilidadeString = sessionStorage.getItem('multipla_visibilidade');
        const visibilidadeDetail = JSON.parse(visibilidadeString);
        return visibilidadeDetail || null; // Set to null if userDetail is false
    }

    function getPermissoes() {
        const permissoesString = sessionStorage.getItem('permissoes');
        const permissaoDetail = JSON.parse(permissoesString);
        return permissaoDetail || null; // Set to null if permissaiDetail is false
    }

    const saveToken = (user, permissoes, token, empresa, multipla_visibilidade, missao, missoes) => {

        sessionStorage.setItem('token', JSON.stringify(token));
        sessionStorage.setItem('user', JSON.stringify(user));
        sessionStorage.setItem('empresa', JSON.stringify(empresa));
        sessionStorage.setItem('permissoes', JSON.stringify(permissoes));
        sessionStorage.setItem('multipla_visibilidade', JSON.stringify(multipla_visibilidade));
        sessionStorage.setItem('missao', JSON.stringify(missao));
        sessionStorage.setItem('missoes', JSON.stringify(missoes));

        setToken(token);

        setPermissoes(permissoes);
        setEmpresa(empresa);
        setMultiplaVisibilidade(multipla_visibilidade);
        setMissao(missao);
        setMissoes(missoes);
        //navigate('/dashboard');
    }

    const saveMissao = (missao) => {
        sessionStorage.setItem('missao', JSON.stringify(missao));
        setMissao(missao);
    }

    const logout = () => {
        sessionStorage.clear();
        //navigate('/entrar');
    }

    const http = axios.create({
        baseURL: url_root + "/api",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        }
    });


    const verificarPermissao = (codigo, operacao) => {

        //console.log('Confirmar permissões, ao verificar');
        //console.log(user_permissoes);

        // Percorre o array de permissões
        for (let i = 0; i < user_permissoes.length; i++) {
            const permissao = user_permissoes[i];

            let op = false;

            if (operacao.toLowerCase() == 'ver') {
                op = permissao.ver == 1 ? true : false;
            } else if (operacao.toLowerCase() == 'atualizar') {
                op = permissao.atualizar == 1 ? true : false;
            } else if (operacao.toLowerCase() == 'adicionar') {
                op = permissao.adicionar == 1 ? true : false;
            } else if (operacao.toLowerCase() == 'remover') {
                op = permissao.remover == 1 ? true : false;
            } else if (operacao.toLowerCase() == 'ativar') {
                op = permissao.ativar == 1 ? true : false;
            } else {
                op = false;
            }

            // Verifica se o código e a operação correspondem
            if (
                permissao.accao.codigo.toLowerCase() === codigo.toLowerCase() && op
            ) {
                return true; // Permissão concedida
            }
        }

        return false; // Permissão não concedida
    };


    return {
        setToken: saveToken,
        setMissaoSelecionada: saveMissao,
        token,
        user,
        getMissao,
        getMissoes,
        user_permissoes,
        getToken,
        http,
        logout,
        url_root,
        logoTipo,
        verificarPermissao,
        empresa_selecionada,
        multipla_visibilidade,
    }
}