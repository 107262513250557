// src/pages/Index.jsx

/* eslint-disable no-dupe-keys */
import React, { useState, useEffect } from "react";
import AuthUser from '../components/AuthUser';
import Footer from '../components/Footer';
import Banner from '../components/Banner';
import ProjetosDestaque from '../components/ProjetosDestaque';
import MenuGeral from '../components/Layout/MenuGeral';

const Projetos = () => {

     // eslint-disable-next-line no-unused-vars
     const { http } = AuthUser();

     useEffect(() => {
 
         const formData = new FormData();
         formData.append('pagina', "projetos");
 
         http.post('/contar-pagina-visitada', formData, {
             headers: { 'Content-Type': 'multipart/form-data' },
         });
     
       }, []);

    return (
        <div>
            <MenuGeral />

            <Banner
                backgroundImage="/images/page-title/page-title-bg-3.png"
                path="Institucional"
                currentPage="Projetos"
            />


            <ProjetosDestaque />

            <Footer />

            {/* Conteúdo adicional da página     */}
        </div>
    );
}

export default Projetos;
