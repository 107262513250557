import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './stylesComponent/bannerPrincipal.css';

const BannerSlider = () => {
    
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true, // Ativa o autoplay
        autoplaySpeed: 5000, // Define a duração de cada slide em milissegundos (por exemplo, 5000ms = 5 segundos)
    };

    return (
        <div className="banner-slider">
            <Slider {...settings}>

                <div className="banner-slide">
                    <div className="banner-content">
                        <div className="banner-text text-start">
                            <div className="banner-label">EQUALIZADOR C&T</div>
                            <div className="banner-title">Consultoria E Treinamentos Em TI</div>
                            <div className="banner-description">Transformando ideias em soluções digitais.</div>
                            <button className="cta-button">Mais informação</button>
                        </div>
                        <div className="banner-image">
                            <img src="/images/banner/design.png" alt="Ícone" />
                        </div>
                    </div>
                    <img src="/images/banner/slider-bg.png" alt="Banner 1" />
                </div>

                <div className="banner-slide">
                    <div className="banner-content">
                        <div className="banner-text text-start">
                            <div className="banner-label">EQUALIZADOR C&T</div>
                            <div className="banner-title">Consultoria</div>
                            <div className="banner-description">Transformando ideias em soluções digitais.</div>
                            <button className="cta-button">Mais informação</button>
                        </div>
                        <div className="banner-image">
                            <img src="/images/banner/design.png" alt="Ícone" />
                        </div>
                    </div>
                    <img src="/images/banner/slider-bg-2.jpg" alt="Banner 1" />
                </div>

                {/* Adicione mais slides conforme necessário */}
            </Slider>
        </div>
    );
}

export default BannerSlider;
