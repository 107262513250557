// src/pages/Index.jsx
/* eslint-disable no-dupe-keys */
import React, { useState, useEffect } from "react";
import AuthUser from '../components/AuthUser';
import Footer from '../components/Footer';
import Banner from '../components/Banner';
import Testemunhos1 from '../components/Testemunhos1';
import MenuGeral from '../components/Layout/MenuGeral';
import ContactoInfo from '../components/ContactoInfo';
import FormularioContacto from '../components/FormularioContacto';

const Faqs = () => {

     // eslint-disable-next-line no-unused-vars
     const { http } = AuthUser();

     useEffect(() => {
 
         const formData = new FormData();
         formData.append('pagina', "contactos");
 
         http.post('/contar-pagina-visitada', formData, {
             headers: { 'Content-Type': 'multipart/form-data' },
         });
     
       }, []);

    return (
        <div>
            <MenuGeral />

            <Banner
                backgroundImage="/images/page-title/IMG_0811.jpg"
                path="Institucional"
                currentPage="Contactos"
            />


            <ContactoInfo />

            <FormularioContacto />

            <Footer />

            {/* Conteúdo adicional da página     */}
        </div>
    );
}

export default Faqs;
