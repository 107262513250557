import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedin, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone, faMapMarkerAlt, faArrowUp } from '@fortawesome/free-solid-svg-icons';


import AuthUser from './AuthUser';
import Spinner from './Spinner.js';

import { SnackbarProvider, enqueueSnackbar, useSnackbar } from 'notistack';

import './stylesComponent/Footer.css';
import { Link } from "react-router-dom";

const Footer = () => {

    const { enqueueSnackbar } = useSnackbar(); // Importe useSnackbar para usar enqueueSnackbar

    // eslint-disable-next-line no-unused-vars
    const { http } = AuthUser();

    // State to track whether the "checkgeral" checkbox is checked
    const [loading, setLoading] = useState(false);

    const [email, setEmail] = useState('');

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth" // Opção para um scroll suave
        });
    };

    const handleContactar = async () => {
        try {


            if (!isEmailValid(email)) {
                enqueueSnackbar('Por favor, forneça um e-mail válido.', { variant: 'error' });
                return;
            }


            setLoading(true);

            // Envie uma solicitação para pesquisar usuario
            const formData = new FormData();
            formData.append('titulo', "Subscrição para receber novidades");
            formData.append('descricao', "Este é um e-mail recebido do formulario de subscrição pelo site da equalizador");
            formData.append('email', email);
            formData.append('telefone', 'Não definido');
            formData.append('nome', 'Não definido');

            const response = await http.post('/contactar-equalizador', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });


            enqueueSnackbar('Recebemos o seu e-mail, muito obrigado.', { variant: 'success' });

            setEmail('');

            console.log('Enviado com sucesso');

            setLoading(false);




        } catch (error) {
            // Exiba uma mensagem de erro se ocorrer um problema
            /*toast.error('Ocorreu um erro pesquisar. Por favor, tente novamente mais tarde.', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });*/

            enqueueSnackbar('Ocorreu um erro pesquisar. Por favor, tente novamente mais tarde.', { variant: 'error' });

            setLoading(false);

        }
    };

    const isEmailValid = (email) => {
        // Expressão regular para validar e-mail
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    if (loading) return (<><Spinner></Spinner></>); else return (
        <>
            <footer>
                <div className="footer bg-light">
                    <div className="">
                        <div className="footer-row1 row">
                            <div className="col-md-8">
                                <h5>Subscreva para ter novidades</h5>
                                <p className="text-muted">Mantenha-se atualizado com os nossos serviços e treinamentos.</p>
                            </div>

                            <div className="col-md-4">
                                <div className="subscribe-form">
                                    <div className="input-group">
                                        <input type="email" className="form-control" placeholder="Digite seu email" value={email}
                                            onChange={(e) => setEmail(e.target.value)} />
                                        <div className="input-group-append">
                                            <button onClick={() => handleContactar()} className="btn btn-primary">Subscreva agora</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-row2 bg-light">
                        <div className="">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-12 coluna-link">
                                    <h3>Sobre nós</h3>
                                    <p className="text-muted">A EQUALIZADOR CONSULTORIA & TREINAMENTO está focada em Consultoria e Treinamento na área de Tecnologias de Informação, tendo como público alvo profissionais da área de tecnologia de informação e estudantes universitários, pequenas, médias e grandes empresas no sector.</p>

                                    <div className="social-icons">
                                        <div className="icon">
                                            <Link to="https://www.facebook.com/equalizador"><FontAwesomeIcon icon={faFacebook} className="icon-large text-white " />
                                            </Link></div>
                                        <div className="icon">
                                            <Link to="https://wa.me/244935689669"><FontAwesomeIcon icon={faWhatsapp} className="icon-large text-white " />
                                            </Link></div>
                                        <div className="icon">
                                            <Link to="https://www.instagram.com/_equalizador_ao/"><FontAwesomeIcon icon={faInstagram} className="icon-large text-white " />
                                            </Link></div>
                                        <div className="icon">
                                            <Link to="https://www.linkedin.com/company/equalizador-c-t/"><FontAwesomeIcon icon={faLinkedin} className="icon-large text-white " />
                                            </Link></div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 coluna-link coluna-link-importante">
                                    <h3>Links importantes</h3>
                                    <ul className="list-unstyled">
                                        <li><Link to="/sobre-nos" onClick={scrollToTop}>Sobre nós</Link></li>
                                        <li><Link to="/projetos" onClick={scrollToTop}>Casos de estudos</Link></li>
                                        <li><Link to="/servicos" onClick={scrollToTop}>Serviços</Link></li>
                                        <li><Link to="/artigos" onClick={scrollToTop}>Artigos</Link></li>
                                        <li><Link to="/contactos" onClick={scrollToTop}>Contactos</Link></li>
                                    </ul>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 coluna-link">
                                    <h3>Serviços</h3>
                                    <ul className="list-unstyled">
                                        <li><Link to="/servico-single" onClick={scrollToTop}>Desenvolvimento Web & Mobile</Link></li>
                                        <li><Link to="/servico-singleB" onClick={scrollToTop}>Infraestrutura e sistema de redes</Link></li>
                                        <li><Link to="/servico-singleC" onClick={scrollToTop}>Business Intelligence</Link></li>
                                    </ul>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 coluna-link">
                                    <h3>Contactos</h3>
                                    <div className="list-unstyled">
                                        <li><FontAwesomeIcon icon={faPhone} className="icon-large text-dark" /> +(244) 935 689 669</li>
                                        <li><FontAwesomeIcon icon={faEnvelope} className="icon-large text-dark" /> geral@equalizador.ao</li>
                                        <li><FontAwesomeIcon icon={faMapMarkerAlt} className="icon-large text-dark" /> Avenida Pedro de Castro Van-Dúnem Loy, Gamek, Vila Eco-Campo, n' 76, Luanda.</li>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-row3">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <p className="text-muted">Direitos de ©2024 EQUALIZADOR. Todos os direitos reservados EQUALIZADOR C&T</p>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <p className="text-muted">Termos & Condições | Politica de Privacidade</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="scroll-to-top" onClick={scrollToTop}>
                    <FontAwesomeIcon icon={faArrowUp} />
                </div>

            </footer>
            {/* <ToastContainer /> */}
        </>
    );
}

export default function WrappedFooter() {
    return (
        <SnackbarProvider>
            <Footer />
        </SnackbarProvider>
    );
}
