import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import './stylesComponent/MissaoVisaoValor.css';

const MissaoVisaoValor = () => {
    const [activeTab, setActiveTab] = useState('Missão');

    const handleTabChange = (tabName) => {
        setActiveTab(tabName);
    };

    return (
        <div className="row missao-visao-valor">
            <div className="col-sm-6">
                <img className="d-block w-100" src="/images/about/about-2.png" alt="" />
            </div>
            <div className="col-sm-6">
                <div className="card-block">

                    <h2 className="title">EQUALIZADOR C&T</h2>

                    <p>EQUALIZADOR CONSULTORIA E TREINAMENTO é especializada em serviços tecnológicos de TI, tais como desenvolvimento web e mobile, gestão de de infraestruturas e sistemas, business intelligence, treinamentos e consultoria.</p>

                    <div className="tabs">

                        <ul className="tab-list">
                            <li className={`tab-title ${activeTab === 'Missão' ? 'active' : ''}`} onClick={() => handleTabChange('Missão')}>Missão</li>
                            <li className={`tab-title ${activeTab === 'Visão' ? 'active' : ''}`} onClick={() => handleTabChange('Visão')}>Visão</li>
                            <li className={`tab-title ${activeTab === 'Valores' ? 'active' : ''}`} onClick={() => handleTabChange('Valores')}>Valores</li>
                        </ul>

                        <div className="tab-content">

                            <div className={`tab-pane ${activeTab === 'Missão' ? 'active' : ''}`}>
                                <p className="tab-description text-dark">Preparar estudantes universitários para o mercado de trabalho, potenciar técnicos de TI do mercado. Prestar serviços de consultoria e Implementações na área de TI à empresas do mercado nacional e estrangeiro.</p>
                                <h3>Nossos serviços</h3>
                                <ul>
                                    <li><FontAwesomeIcon icon={faCheck} /> Desenvolvimento Web & Mobile</li>
                                    <li><FontAwesomeIcon icon={faCheck} /> Implementação de infraestruturas e sistemas</li>
                                    <li><FontAwesomeIcon icon={faCheck} /> Business intelligence</li>
                                    <li><FontAwesomeIcon icon={faCheck} /> Consultoria e treinamento</li>
                                </ul>
                                
                            </div>

                            <div className={`tab-pane ${activeTab === 'Visão' ? 'active' : ''}`}>
                                <p className="tab-description py-3">Implementar um laboratório técnico, devidamente equipado com tecnologias de ultima geração do mercado mundial, onde empresas enviam os seus técnicos para serem treinados e/ou capacitados.</p>
                                <h3>Nossos serviços</h3>
                                <ul>
                                    <li><FontAwesomeIcon icon={faCheck} /> Desenvolvimento Web & Mobile</li>
                                    <li><FontAwesomeIcon icon={faCheck} /> Implementação de infraestruturas e sistemas</li>
                                    <li><FontAwesomeIcon icon={faCheck} /> Business intelligence</li>
                                    <li><FontAwesomeIcon icon={faCheck} /> Consultoria e treinamento</li>
                                </ul>
                                
                            </div>

                            <div className={`tab-pane ${activeTab === 'Valores' ? 'active' : ''}`}>
                                <p className="tab-description">A nossa prioridade consiste na prestação de um serviço altamente qualificado, contando com a colaboração de técnicos certificados e com experiência no mercado de trabalho.</p>
                                <h3>Nossos serviços</h3>
                                <ul>
                                    <li><FontAwesomeIcon icon={faCheck} /> Desenvolvimento Web & Mobile</li>
                                    <li><FontAwesomeIcon icon={faCheck} /> Implementação de infraestruturas e sistemas</li>
                                    <li><FontAwesomeIcon icon={faCheck} /> Business intelligence</li>
                                    <li><FontAwesomeIcon icon={faCheck} /> Consultoria e treinamento</li>
                                </ul>
                                
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MissaoVisaoValor;
