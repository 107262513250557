import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import './stylesComponent/ContactoInfo.css'; // Importando o arquivo de estilos

const ContactoInfo = () => {
    return (
        <section className="contact-info">
            <div className="">
                <div className="row">
                    <div className="col-md-4">
                        <div className="contacto-container" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                            <div className="icon-container">
                                <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
                            </div>
                            <h3 className="title">Endereço</h3>
                            <p className="description">Avenida Pedro de Castro Van-Dúnem Loy, Gamek, Vila Eco-Campo, n' 76, Luanda.</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="contacto-container" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                            <div className="icon-container">
                                <FontAwesomeIcon icon={faEnvelope} className="icon" />
                            </div>
                            <h3 className="title">Email</h3>
                            <p className="description">geral@equalizador.ao</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="contacto-container" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                            <div className="icon-container">
                                <FontAwesomeIcon icon={faPhone} className="icon" />
                            </div>
                            <h3 className="title">Telefone</h3>
                            <p className="description">(+244) 935 689 669</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

const handleMouseOver = (e) => {
    /*e.currentTarget.querySelector('.icon').style.color = '#fff';
    e.currentTarget.style.backgroundColor = '#fff';*/
}

const handleMouseOut = (e) => {
    /*e.currentTarget.querySelector('.icon').style.color = '#086ad8';
    e.currentTarget.style.backgroundColor = '#fff';*/
}

export default ContactoInfo;
