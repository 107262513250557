import React from 'react';
import { Link } from 'react-router-dom';
import './stylesComponent/TreinamentosItem.css'; // Importando o arquivo de estilos
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';

const TreinamentosItem = () => {
    return (
        <section className="treinamentos-destaque">
            {/* Título e Descrição centralizados */}
            <div className="text-center mb-2 d-flex flex-column align-items-center">
                <h1 className="titulo">Treinamentos em Destaque</h1>
                <p className="descricao text-center text-dark">Explore nossos treinamentos e capacite-se para o mercado de trabalho.</p>
                <div className="bar-title "></div>
            </div>

            {/* Colunas com os treinamentos */}
            <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <div className="treinamento">
                        <div className="imagem">
                            <Link><img src="/images/blog/TRNMT01.png" alt="Treinamento 1" /></Link>
                        </div>
                        <div className="conteudo">
                          
                            <Link><h3 className="titulo">Excel Do Básico Ao Avançado</h3></Link>
                            <p className="descricao">30 horas de formação, 12 modulos cobrindo desde os conceitos mais basicos do excel ao Avançado </p>

                            <Link>
                            <span className="ver-turmas"><FontAwesomeIcon icon={faCalendar} />{" "}Ver turmas em inscrição</span></Link>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <div className="treinamento">
                        <div className="imagem">
                            <Link><img src="/images/blog/TRNMT02.png" alt="Treinamento 2" /></Link>
                        </div>
                        <div className="conteudo">
                           
                            <Link><h3 className="titulo">CCNA</h3></Link>
                            <p className="descricao">80 horas de formação, preparando-se para o exame de certificação CISCO, com formadores especialistas na materia.</p>

                            <Link>
                            <span className="ver-turmas"><FontAwesomeIcon icon={faCalendar} />{" "}Ver turmas em inscrição</span></Link>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <div className="treinamento">
                        <div className="imagem">
                            <Link><img src="/images/blog/TRNMT03.png" alt="Treinamento 3" /></Link>
                        </div>
                        <div className="conteudo">
                          
                            <Link><h3 className="titulo">Power BI</h3></Link>
                            <p className="descricao">Use o Power BI para transformar dados em insights estratégicos e impulsionar resultados empresariais.</p>

                            <Link>
                            
                            <span className="ver-turmas"> <FontAwesomeIcon icon={faCalendar} />{" "} Ver turmas em inscrição</span></Link>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
}

export default TreinamentosItem;
