import React, { useState, useEffect } from "react";
import './stylesComponent/FormularioContacto.css';

import AuthUser from './AuthUser';
import { ToastContainer, toast } from 'react-toastify';
import Spinner from './Spinner.js';


import { SnackbarProvider, enqueueSnackbar, useSnackbar } from 'notistack';

const FormularioContacto = () => {

    // eslint-disable-next-line no-unused-vars
    const { http } = AuthUser();

    // State to track whether the "checkgeral" checkbox is checked
    const [loading, setLoading] = useState(false);

    const [email, setEmail] = useState('');
    const [telefone, setTelefone] = useState('');
    const [nome, setNome] = useState('');
    const [assunto, setAssunto] = useState('');
    const [mensagem, setMensagem] = useState('');

    
    const handleContactar = async () => {
        try {
            // Verificar se todos os campos estão preenchidos com mais de um caractere
            if (
                email.length > 1 &&
                telefone.length > 1 &&
                nome.length > 1 &&
                assunto.length > 1 &&
                mensagem.length > 1
            ) {
                // Verificar se o e-mail é válido
                if (!isEmailValid(email)) {
                    enqueueSnackbar('Por favor, forneça um e-mail válido.', { variant: 'error' });
                    return;
                }

                setLoading(true);
                const formData = new FormData();
                formData.append('titulo', assunto);
                formData.append('descricao', mensagem);
                formData.append('email', email);
                formData.append('telefone', telefone);
                formData.append('nome', nome);

                const response = await http.post('/contactar-equalizador', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });

                console.log(response);

                setLoading(false);
                enqueueSnackbar('Recebemos o seu e-mail, muito obrigado.', { variant: 'success' });

                setNome('');
                setTelefone('');
                setEmail('');
                setAssunto('');
                setMensagem('');

            } else {
                enqueueSnackbar('Por favor, preencha todos os campos.', { variant: 'error' });
            }
        } catch (error) {
            enqueueSnackbar('Ocorreu um erro. Por favor, tente novamente mais tarde.', { variant: 'error' });
            setLoading(false);
        }
    };

    const isEmailValid = (email) => {
        // Expressão regular para validar e-mail
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };
    

      if (loading) return (<><Spinner></Spinner></>); else return (
        <section className="formulario-contacto">
            <div className="">
                <div className="row">
                    <div className="col-md-6 mb-4">

                        <div className="informacoes">
                            <h3 className="titulo">Tem alguma questão sobre nós?</h3>
                            <p className="descricao">Preencha o formulário abaixo com a sua solicitação, e nós responderemos dentro de instantes .</p>
                            <div className="bar-title "></div>
                        </div>

                        <div className="formulario-container">
                            <form>
                                <div className="row mb-3">
                                    <div className="col-md-6">
                                        <label htmlFor="nome" className="form-label">Nome</label>
                                        <input type="text" className="form-control" id="nome" value={nome}
              onChange={(e) => setNome(e.target.value)} />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="email" className="form-label">Email</label>
                                        <input type="email" className="form-control" id="email" value={email}
              onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-12">
                                        <label htmlFor="telefone" className="form-label">Telefone</label>
                                        <input type="tel" className="form-control" id="telefone" value={telefone}
              onChange={(e) => setTelefone(e.target.value)} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-12">
                                        <label htmlFor="assunto" className="form-label">Assunto</label>
                                        <input type="text" className="form-control" id="assunto" value={assunto}
              onChange={(e) => setAssunto(e.target.value)} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-12">
                                        <label htmlFor="mensagem" className="form-label">Mensagem</label>
                                        <textarea className="form-control textarea" id="mensagem" rows="10" value={mensagem}
              onChange={(e) => setMensagem(e.target.value)}></textarea>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <span  className="btn" onClick={() => handleContactar()}>Enviar a Mensagem</span>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                    <div className="col-md-6 mb-4">

                        <img src="/images/contact.png" alt="Contactos" className="faqs-thumbnail" />

                    </div>
                </div>
            </div>

        </section>
    );
}


export default function WrappedFooter() {
    return (
        <SnackbarProvider>
            <FormularioContacto />
        </SnackbarProvider>
    );
}

