// src/pages/Index.jsx

/* eslint-disable no-dupe-keys */
import React, { useState, useEffect } from "react";
import MenuSuperior from '../components/Layout/MenuSuperior';
import BannerSlider from '../components/BannerSlider';
import Footer from '../components/Footer';
import FeatureServicos from '../components/FeatureServicos';
import InfoVideo from '../components/InfoVideo';
import ProjetosDestaque from '../components/ProjetosDestaque';
import NewsLetter1 from '../components/NewsLetter1';
import Testemunhos1 from '../components/Testemunhos1';
import FaqsDestaque from '../components/FaqsDestaque';
import ArtigosDestaque from '../components/ArtigosDestaque';

import AuthUser from '../components/AuthUser';

const Index = () => {

    // eslint-disable-next-line no-unused-vars
    const { http } = AuthUser();

    useEffect(() => {

        const formData = new FormData();
        formData.append('pagina', "inicio");

        http.post('/contar-pagina-visitada', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
    
      }, []);

    return (
        <div>
            
            <MenuSuperior />
            
            <BannerSlider />
            
            <FeatureServicos />
            
            <InfoVideo />

            <ProjetosDestaque />

            <NewsLetter1  />

            <Testemunhos1  />

            <FaqsDestaque  />

            <ArtigosDestaque  />

            <Footer />
            
            {/* Conteúdo adicional da página     */}
        </div>
    );
}

export default Index;
