import React, { useState } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import './stylesComponent/InfoVideo.css'; // Importando o arquivo de estilos

const InfoVideo = () => {
    const [isOpen, setIsOpen] = useState(false); // Estado para controlar se o modal está aberto

    // Função para abrir o modal
    const openModal = () => {
        setIsOpen(true);
    };

    // Função para fechar o modal
    const closeModal = () => {
        setIsOpen(false);
    };

    return (
        <section className="info-video">
            <div className="">
                <div className="row">
                    <div className="col-md-6 mb-4">
                        <div className="info-content">
                            <h2 className="titulo">Transformando ideias Em Soluções Digitais</h2>
                            <div className="bar-title "></div>
                            <p className="descricao infoVideo-descricao">Somos uma consultoria expert em oferecer soluções digitais abrangentes e inovadoras. Através de nossa especialização em desenvolvimento de sistemas, sites, aplicativos mobile, gestão de infraestrututras e sistemas de redes, bem como a analise e tratamento de dados ( BI ), trazemos à vida ideias transformadoras, garantindo experiências digitais memoráveis.</p>
                            <div className="progress-container">
                                <div className="progress-item">
                                    <div className='descricao-percentagem d-flex justify-content-between'>
                                        <span className="progress-label">Implementação:</span>
                                        <span className="progress-value">80%</span>
                                    </div>
                                    <div className='fundo-progress'>
                                        <div className="progress-bar" style={{ width: '80%' }}></div></div>
                                </div>
                                <div className="progress-item">
                                    <div className='descricao-percentagem d-flex justify-content-between'>
                                        <span className="progress-label">Entrega de projeto:</span>
                                        <span className="progress-value">100%</span></div>
                                    <div className='fundo-progress'>
                                        <div className="progress-bar" style={{ width: '100%' }}></div></div>
                                </div>
                                <div className="progress-item">
                                    <div className='descricao-percentagem d-flex justify-content-between'>
                                        <span className="progress-label">Planificação:</span>
                                        <span className="progress-value">90%</span></div>
                                    <div className='fundo-progress'>
                                        <div className="progress-bar" style={{ width: '90%' }}></div></div>
                                </div>
                                {/* <div className="progress-item">
                                    <div className='descricao-percentagem d-flex justify-content-between'>
                                        <span className="progress-label">Suporte:</span>
                                        <span className="progress-value">20%</span></div>
                                    <div className='fundo-progress'>
                                        <div className="progress-bar" style={{ width: '20%' }}></div></div>
                                </div>
                                <div className="progress-item">
                                    <div className='descricao-percentagem d-flex justify-content-between'>
                                        <span className="progress-label">Manutenção:</span>
                                        <span className="progress-value">10%</span></div>
                                    <div className='fundo-progress'>
                                        <div className="progress-bar" style={{ width: '10%' }}></div></div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-4">
                        <div className="video-overlay">
                            <img src="/images/about/about.png" alt="Vídeo" className="video-thumbnail" />
                            <div className="play-icon text-primary" onClick={openModal}> {/* Adicionando evento de clique */}
                                <FontAwesomeIcon icon={faYoutube} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          
            {/* Modal para reproduzir o vídeo do YouTube */}
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                contentLabel="Modal de Vídeo"
                className="custom-modal"
                overlayClassName="custom-overlay"
            >
                <FontAwesomeIcon icon={faTimes} className="close-modal" onClick={closeModal} />
                <div className="video-container">
                    <iframe
                        title="Vídeo do YouTube"
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/qGlTilfiwiQ"
                        frameborder="0"
                        allowfullscreen
                    ></iframe>
                </div>
            </Modal>

             {/* Botão para abrir o modal */}
             {/* <div className="play-icon text-primary" onClick={openModal}>
                <FontAwesomeIcon icon={faYoutube} />
            </div> */}


        </section>
    );
}

export default InfoVideo;
