import React from 'react';
import AccordionItem from './AccordionItem'; // Importe o componente AccordionItem
import './stylesComponent/FaqsDestaque.css'; // Importando o arquivo de estilos

const FaqsDestaque = () => {
    return (
        <section className="faqs-destaque">
            {/* Título e Descrição centralizados */}
            <div className="text-center mb-1 d-flex flex-column align-items-center">
                <h2 className="titulo">Perguntas Feitas Frequentemente</h2>
                <p className="descricao text-center">Observe abaixo algumas questões que podera fazer-se sobre nós e suas respectivas respostas, não esite em contactar-nos para mais informação.</p>
                <div className="bar-title "></div>
            </div>
            
            {/* Coluna com o Accordion das perguntas */}
            <div className="row">
                <div className="col-md-6 mb-4">
                    {/* Implementação do Accordion */}
                    <AccordionItem question="Como posso garantir a segurança da minha infraestrutura de rede?" answer="Oferecemos uma gama de soluções de segurança de rede, incluindo firewalls avançados, detecção de intrusos e monitoramento constante para proteger sua infraestrutura contra ameaças cibernéticas." />
                    <AccordionItem question="Quais são as vantagens de desenvolver um aplicativo móvel para o meu negócio?" answer="Um aplicativo móvel pode aumentar o envolvimento do cliente, melhorar a acessibilidade aos seus serviços e ajudar a construir uma marca forte. Além disso, oferece oportunidades para monetização e análise de dados valiosos." />
                    <AccordionItem question="Como o Power BI pode beneficiar minha empresa?" answer="O Power BI é uma poderosa ferramenta de análise de dados que permite transformar grandes volumes de informações em insights acionáveis ​​e visualmente atraentes. Ele pode ajudar a identificar padrões, tendências e oportunidades de negócios." />
                    <AccordionItem question="Quais são os principais elementos do desenvolvimento web que devo considerar para o sucesso do meu site?" answer="Além de um design atraente e responsivo, é crucial considerar a usabilidade, a otimização para mecanismos de busca (SEO), a segurança e a integração com plataformas de terceiros." />
                    <AccordionItem question="Como vocês personalizam os treinamentos em TI para atender às necessidades específicas da minha empresa?" answer="Nossos treinamentos em TI são altamente personalizados e adaptados às necessidades e objetivos da sua empresa. Realizamos uma avaliação detalhada das habilidades existentes e desenvolvemos um programa de treinamento sob medida para atender às suas necessidades específicas. Nossos treinamentos personalizados incluem coffe-break" />
                </div>
                
                {/* Coluna com a imagem */}
                <div className="col-md-6 mb-4">
                    <img src="/images/faq.png" alt="FAQs" className="faqs-thumbnail" />
                </div>
            </div>
        </section>
    );
}

export default FaqsDestaque;
