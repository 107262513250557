import React from 'react';
import { Link } from 'react-router-dom';
import './stylesComponent/ArtigoSidebar.css'; // Importando o arquivo de estilos

const ArtigoSidebar = () => {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth" // Opção para um scroll suave
        });
    };
    
    return (
        <div className="artigo-sidebar">
            {/* Input de pesquisa */}
            <div>
                <input type="text" placeholder="Pesquisar" className="search-input" />
            </div>

            {/* Título "Artigos recentes" */}
            <h3 className="sidebar-title">Artigos recentes</h3>
            <hr className="sidebar-line" />

            {/* Mosaicos dos artigos */}
            <div className="artigo-widget">
                <div className="artigo-container">
                    <img src="/images/blog/ARTG01.png" alt="Artigo 1" className="artigo-img" />
                    <div className="artigo-content">
                        <span className="artigo-date">20 de Abril de 2024</span>
                        <h4 className="artigo-title"><Link to="/artigo1" onClick={scrollToTop} className="text-dark">Como optimizar sua infraestrutura de rede para melhorar o desempenho e segurança</Link></h4>
                    </div>
                </div>
            </div>

            <div className="artigo-widget">
                <div className="artigo-container">
                    <img src="/images/blog/ARTG04.png" alt="Artigo 2" className="artigo-img" />
                    <div className="artigo-content">
                        <span className="artigo-date">21 de Abril de 2024</span>
                        <h4 className="artigo-title"><Link to="/artigo2" onClick={scrollToTop} className="text-dark">Estrategias eficientes para engajar sua audiência online</Link></h4>
                    </div>
                </div>
            </div>

            <div className="artigo-widget">
                <div className="artigo-container">
                    <img src="/images/blog/ARTG03.png" alt="Artigo 3" className="artigo-img" />
                    <div className="artigo-content">
                        <span className="artigo-date">22 de Abril de 2024</span>
                        <h4 className="artigo-title"><Link to="/artigo3" onClick={scrollToTop} className="text-dark">Transformando dados em insights valiosos.</Link></h4>
                    </div>
                </div>
            </div>

            <div className="artigo-widget">
                <div className="artigo-container">
                    <img src="/images/blog/ARTG02.png" alt="Artigo 3" className="artigo-img" />
                    <div className="artigo-content">
                        <span className="artigo-date">22 de Abril de 2024</span>
                        <h4 className="artigo-title"><Link to="/artigo4" onClick={scrollToTop} className="text-dark">Importância do treinamento em TI</Link></h4>
                    </div>
                </div>
            </div>


        </div>
    );
}

export default ArtigoSidebar;
