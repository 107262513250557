// src/pages/Index.jsx
/* eslint-disable no-dupe-keys */
import React, { useState, useEffect } from "react";
import AuthUser from '../components/AuthUser';
import Footer from '../components/Footer';
import Banner from '../components/Banner';
import MenuGeral from '../components/Layout/MenuGeral';
import ServicoVideoDescricao from '../components/ServicoVideoDescricao';
import TextoImagemColunasVA from '../components/TextoImagemColunasVA';
import TextoImagemColunasVB from '../components/TextoImagemColunasVB';

const ServicoSingle = () => {

     // eslint-disable-next-line no-unused-vars
     const { http } = AuthUser();

     useEffect(() => {
 
         const formData = new FormData();
         formData.append('pagina', "servico-single");
 
         http.post('/contar-pagina-visitada', formData, {
             headers: { 'Content-Type': 'multipart/form-data' },
         });
     
       }, []);

    return (
        <div>
            <MenuGeral />

            <Banner
                backgroundImage="/images/page-title/dev.png"
                path="Serviço"
                currentPage="Desenvolvimento web e Mobile"
            />

            <ServicoVideoDescricao 
            videoUrl={"https://www.youtube.com/embed/HS7__gPA3IE"}
            imagem={"/images/agency-services/dev-demo.jpg"}
            titulo={"Transformando ideias em Soluções Digitais"}
            descricao={"Nossa equipe de desenvolvimento web e mobile está pronta para transformar suas ideias em realidade digital. Combinando criatividade, expertise técnica e as mais recentes tecnologias, oferecemos soluções personalizadas que cativam seus usuários e impulsionam o crescimento do seu negócio."}
            />

            <TextoImagemColunasVA
                imagem={"/images/service-details/dev3.jpg"}
                listaItens={[
                    { titulo: "Transformando ideias em Soluções Digitai", descricao: "Nossa equipe de desenvolvimento web e mobile está pronta para transformar suas ideias em realidade digital. Combinando criatividade, expertise técnica e as mais recentes tecnologias, oferecemos soluções personalizadas que cativam seus usuários e impulsionam o crescimento do seu negócio." },
                    { titulo: "Desenvolvimento de Sites.", descricao: "Construímos sites cativantes e funcionais para destacar sua marca na web. \n Desenvolvemos sites responsivos, otimizados para dispositivos móveis, garantindo uma presença online eficaz em todas as plataformas." },
                    { titulo: "Desenvolvimento de Sistemas Web.", descricao: "Desenvolvemos sistemas web robustos para otimizar seus processos online. \n Nossa experiência abrange a criação de sistemas web robustos, utilizando as tecnologias mais avançadas e adaptáveis às necessidades únicas de cada cliente." }
                ]}
            />

            <TextoImagemColunasVB
                imagem={"/images/service-details/dev2.jpg"}
                listaItens={[
                    { titulo: "Integração de API", descricao: "Facilitamos a comunicação entre sistemas com integração eficiente de APIs. Enriquecemos suas soluções com a integração de APIs de terceiros, ou criamo-las de forma personalizada, oferecendo funcionalidades avançadas e uma experiência do usuário aprimorada." },
                    { titulo: "Manutenção e Atualizações", descricao: "Garantimos a manutenção e atualizações contínuas para seu software operar sempre no auge. Comprometemo-nos em manter seus aplicativos e sistemas sempre atualizados, funcionando de maneira eficiente e livre de problemas." },
                    { titulo: "Design UI/UX", descricao: "Design UI/UX intuitivo que eleva a experiência do usuário ao máximo. Nossa equipe de design se dedica a criar interfaces visualmente envolventes e experiências de usuário intuitivas para garantir que seus aplicativos e sites se destaquem." }
                ]}
            />


            <Footer />

            {/* Conteúdo adicional da página     */}
        </div>
    );
}

export default ServicoSingle;
