// src/Router.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Index from './pages/Index';
import Contact from './pages/Contact';
import SobreNos from './pages/SobreNos';
import Faqs from './pages/Faqs';
import Servicos from './pages/Servicos';
import Treinamentos from './pages/Treinamentos';
import Projetos from './pages/Projetos';
import Artigos from './pages/Artigos';
import ServicoSingle from './pages/ServicoSingle';
import ServicoSingleB from './pages/ServicoSingleB';
import ServicoSingleC from './pages/ServicoSingleC';
import ArtigosSingle1 from './pages/ArtigosSingle1';
import ArtigosSingle2 from './pages/ArtigosSingle2';
import ArtigosSingle3 from './pages/ArtigosSingle3';
import ArtigosSingle4 from './pages/ArtigosSingle4';
// Import other components as needed

const AppRouter = () => {
    return ( <
        Router >
        <
        Routes >

        <
        Route exact path = "/"
        element = { < Index / > }
        /> <
        Route path = "/contactos"
        element = { < Contact / > }
        /> <
        Route path = "/sobre-nos"
        element = { < SobreNos / > }
        /> <
        Route path = "/faqs"
        element = { < Faqs / > }
        />

        <
        Route path = "/servicos"
        element = { < Servicos / > }
        /> <
        Route path = "/treinamentos"
        element = { < Treinamentos / > }
        /> <
        Route path = "/projetos"
        element = { < Projetos / > }
        /> <
        Route path = "/artigos"
        element = { < Artigos / > }
        /> <
        Route path = "/servico-single"
        element = { < ServicoSingle / > }
        />

        <
        Route path = "/servico-singleB"
        element = { < ServicoSingleB / > }
        />

        <
        Route path = "/servico-singleC"
        element = { < ServicoSingleC / > }
        />

        <
        Route path = "/artigo1"
        element = { < ArtigosSingle1 / > }
        />

        <
        Route path = "/artigo2"
        element = { < ArtigosSingle2 / > }
        />

        <
        Route path = "/artigo3"
        element = { < ArtigosSingle3 / > }
        />

        <
        Route path = "/artigo4"
        element = { < ArtigosSingle4 / > }
        />

        { /* Add more routes for other pages */ }

        <
        /Routes> < /
        Router >
    );
};

export default AppRouter;