import React, { useState } from 'react';

const AccordionItem = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="accordion-item">
            <div className="accordion-header" onClick={toggleAccordion}>
                <span className={isOpen ? "icon-minus" : "icon-plus"}></span>
                <label>{question}</label>
            </div>
            {isOpen && (
                <div className="accordion-content">
                    <p>{answer}</p>
                </div>
            )}
        </div>
    );
}

export default AccordionItem;
