import React from 'react';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './stylesComponent/Testemunhos1.css';

const Testemunhos1 = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        appendDots: dots => (
            <ul style={{ marginTop: '50px' }}>
                {dots}
            </ul>
        ),
        customPaging: i => (
            <button className="dot"></button>
        ),
        dotsClass: 'slick-dots',
    };

    return (
        <section className="testemunhos1">
            <div className="section-heading text-center">
                <h2>O que as pessoas mais prezam nos serviços da EQUALIZADOR C&T?</h2>
                <p>Acompanhe o testemunho de quem recebeu os nossos serviços.</p>
                <div className="bar-title "></div>
            </div>
            <Slider {...settings}>
                <div>
                    <div className="testimonial-item">
                        <FontAwesomeIcon icon={faQuoteLeft} className="quote-icon" />
                        <p className="testimonial-text">Estamos muito satisfeitos com os serviços de treinamento em TI fornecidos pela EQUALIZADOR. Os instrutores são altamente qualificados e oferecem uma abordagem prática e personalizada, o que nos permitiu capacitar nossa equipe com as habilidades necessárias para enfrentar os desafios tecnológicos do mercado atual.</p>
                        <div className="testimonial-author">
                            <h3 className="author-name">Ricardo Saul</h3>
                            <span className="author-position">Web Developer</span>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="testimonial-item">
                        <FontAwesomeIcon icon={faQuoteLeft} className="quote-icon" />
                        <p className="testimonial-text">Contratamos a EQUALIZADOR para desenvolver nosso aplicativo móvel e ficamos extremamente satisfeitos com os resultados. Eles entregaram um produto de alta qualidade dentro do prazo e orçamento estabelecidos, demonstrando grande profissionalismo e expertise técnica.</p>
                        <div className="testimonial-author">
                            <h3 className="author-name">Monica Bartolomeu</h3>
                            <span className="author-position">Web designer</span>
                        </div>
                    </div>
                </div>
                {/* Adicione mais itens de testemunho conforme necessário */}
            </Slider>
        </section>
    );
}

export default Testemunhos1;
