import React from 'react';
import AccordionItem from './AccordionItem'; // Importe o componente AccordionItem
import './stylesComponent/FaqsDestaque.css'; // Importando o arquivo de estilos

const FaqsDestaque2 = () => {
    return (
        <section className="faqs-destaque">
            
            {/* Coluna com o Accordion das perguntas */}
            <div className="row">

                 {/* Coluna com a imagem */}
                 <div className="col-md-6 mb-4">
                    <img src="/images/tab.png" alt="FAQs" className="faqs-thumbnail" />
                </div>

                <div className="col-md-6 mb-4">
                    {/* Implementação do Accordion */}
                    <AccordionItem question="Como vocês personalizam os treinamentos em TI para atender às necessidades específicas da minha empresa?" answer="Nossos treinamentos em TI são altamente personalizados e adaptados às necessidades e objetivos da sua empresa. Realizamos uma avaliação detalhada das habilidades existentes e desenvolvemos um programa de treinamento sob medida para atender às suas necessidades específicas. Nossos treinamentos personalizados incluem coffe-break" />
                    <AccordionItem question="Como posso agendar uma consulta para discutir meus requisitos de desenvolvimento web ou móvel?" answer="Ficamos felizes em ajudar! Você pode entrar em contato conosco através do formulário de contato em nosso site ou ligar para o nosso número de telefone para agendar uma consulta com um de nossos especialistas em desenvolvimento." />
                    <AccordionItem question="Vocês oferecem suporte técnico contínuo após a conclusão do projeto?" answer="Sim, oferecemos suporte técnico contínuo para garantir que seu projeto funcione sem problemas. Nossa equipe de suporte está disponível para ajudar com quaisquer questões ou problemas que possam surgir após a conclusão do projeto." />
                </div>
                
               
            </div>
        </section>
    );
}

export default FaqsDestaque2;
