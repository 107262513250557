// src/pages/Index.jsx
/* eslint-disable no-dupe-keys */
import React, { useState, useEffect } from "react";
import AuthUser from '../components/AuthUser';
import Footer from '../components/Footer';
import Banner from '../components/Banner';
import MenuGeral from '../components/Layout/MenuGeral';
import ServicoVideoDescricao from '../components/ServicoVideoDescricao';
import TextoImagemColunasVA from '../components/TextoImagemColunasVA';
import TextoImagemColunasVB from '../components/TextoImagemColunasVB';

const ServicoSingleC = () => {

     // eslint-disable-next-line no-unused-vars
     const { http } = AuthUser();

     useEffect(() => {
 
         const formData = new FormData();
         formData.append('pagina', "servico-single");
 
         http.post('/contar-pagina-visitada', formData, {
             headers: { 'Content-Type': 'multipart/form-data' },
         });
 
     }, []);
     
    return (
        <div>
            <MenuGeral />

            <Banner
                backgroundImage="/images/page-title/bi.png"
                path="Serviço"
                currentPage="Business Intelligence"
            />

            <ServicoVideoDescricao 
            videoUrl={"https://www.youtube.com/embed/NFiHxwONk8o"}
            imagem={"/images/service-details/bi1.jpg"}
            titulo={"Desbloqueie o potencial dos seus dados com nosso serviço de Business Intelligence (BI) usando o Power BI"}
            descricao={"Nossa equipe de especialistas ajuda você a transformar dados em insights acionáveis, permitindo que você tome decisões informadas e estratégicas que impulsionam o crescimento e o sucesso do seu negócio."}
            />

            <TextoImagemColunasVA
                imagem={"/images/service-details/bi2.jpg"}
                listaItens={[
                    { titulo: "Integração de dados", descricao: "Criamos conexões que possibilitam reunir em um único arquivo Pbix. dados de diferentes fontes." },
                    { titulo: "Desenvolvimento dos modelos dos dados.", descricao: "Facilitamos a comunicação de informações provenientes de outras tabelas o que permitirá analises aprofundadas." },
                    { titulo: "Desenvolvimentode Dashboards e Relatórios.", descricao: "Construímos Dashboards e relatórios para melhor compreender os dados da organização, e tomar decisões acertadas." }
                ]}
            />

            <TextoImagemColunasVB
                imagem={"/images/service-details/bi3.jpg"}
                listaItens={[
                    { titulo: "Integração de API", descricao: "Facilitamos a comunicação entre sistemas com integração eficiente de APIs. \n Enriquecemos suas soluções com a integração de APIs de terceiros, ou criamo-las de forma personalisada, oferecendo funcionalidades avançadas e uma experiência do usuário aprimorada." },
                    { titulo: "Treinamentos e Consultoria", descricao: "Oferecemos consultoria e treinamento em BI para impulsionar sua estratégia empresarial." },
                    { titulo: "Visualização de Dados Avançada e Interativa", descricao: "Utilizando as poderosas ferramentas de visualização do Power BI, transformamos seus dados em gráficos e relatórios interativos e fáceis de entender, permitindo uma análise profunda e uma compreensão mais clara dos padrões e tendências." },
                    { titulo: "Integração de Dados sem Esforço", descricao: "Integramos facilmente dados de várias fontes, como bancos de dados, planilhas e serviços de nuvem, proporcionando uma visão unificada e abrangente das operações e desempenho do seu negócio." },
                    { titulo: "Análise Preditiva e Modelagem Avançada", descricao: "Utilizando recursos avançados de análise preditiva e modelagem no Power BI, ajudamos você a prever tendências futuras, identificar oportunidades de negócios e antecipar desafios, capacitando-o a tomar decisões proativas e estratégicas." },
                    { titulo: "Segurança e Conformidade de Dados", descricao: "Priorizamos a segurança e a conformidade de dados, implementando medidas robustas de proteção e controle de acesso para garantir a confidencialidade, integridade e disponibilidade dos seus dados sensíveis." }
                ]}
            />


            <Footer />

            {/* Conteúdo adicional da página     */}
        </div>
    );
}

export default ServicoSingleC;
