// src/pages/Index.jsx
/* eslint-disable no-dupe-keys */
import React, { useState, useEffect } from "react";
import AuthUser from '../components/AuthUser';
import Footer from '../components/Footer';
import Banner from '../components/Banner';
import MenuGeral from '../components/Layout/MenuGeral';
import ServicoVideoDescricao from '../components/ServicoVideoDescricao';
import TextoImagemColunasVA from '../components/TextoImagemColunasVA';
import TextoImagemColunasVB from '../components/TextoImagemColunasVB';

const ServicoSingleB = () => {

    // eslint-disable-next-line no-unused-vars
    const { http } = AuthUser();

    useEffect(() => {

        const formData = new FormData();
        formData.append('pagina', "servico-single");

        http.post('/contar-pagina-visitada', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });

    }, []);

    return (
        <div>
            <MenuGeral />

            <Banner
                backgroundImage="/images/page-title/redes.png"
                path="Serviço"
                currentPage="Infraestrutura e sistemas de Rede"
            />

            <ServicoVideoDescricao
                videoUrl={"https://www.youtube.com/embed/qGlTilfiwiQ"}
                imagem={"/images/service-details/redes1.jpg"}
                titulo={"Fortalecemos o seu presente, conectando a sua empresa ao futuro"}
                descricao={"Garantimos operações eficientes por meio da implementação e manutenção de infraestruturas e sistemas, proporcionando estabilidade e segurança para sua jornada digital."}
            />

            <TextoImagemColunasVA
                imagem={"/images/service-details/redes2.jpg"}
                listaItens={[
                    { titulo: "Redes IP", descricao: "Implementação de Redes; - Cabeamento Estruturado (xTP/Fibra); - Conexão de sites remotos ; - Monitoramento de Rede; - Sistema de Telefonia VoIP; - Implementação de Redes Wi-Fi" },
                    { titulo: "Segurança", descricao: "Soluções de Firewalls; - Softwares Antivírus e Antimalwares; - Gestão de Identidade e Acesso; - Auditoria e Conformidade; - Implementação de VPN; - Segurança Electrónica" },
                    { titulo: "Sistemas", descricao: "Implementação de Servidores; - Soluções de Backup e Recovery; - Sistemas de Virtualização; - Armazenamento em Nuvem; - Administração Remota; - Storagee Protecção de dados" }
                ]}
            />

            <TextoImagemColunasVB
                imagem={"/images/service-details/redes3.jpg"}
                listaItens={[
                    { titulo: "Projeto Personalizado para sua Infraestrutura de TI", descricao: "Trabalhamos em estreita colaboração com sua equipe para entender suas necessidades específicas e projetar uma infraestrutura de TI sob medida que atenda aos seus requisitos de desempenho, segurança e escalabilidade." },
                    { titulo: "Implementação Profissional e Eficiente", descricao: "Nossos engenheiros altamente qualificados lidam com a implementação de sua infraestrutura e sistemas de rede de forma profissional e eficiente, minimizando interrupções e garantindo uma transição suave para novas soluções." },
                    { titulo: "Monitoramento Proativo e Manutenção Preventiva", descricao: "Oferecemos serviços de monitoramento proativo e manutenção preventiva para garantir que sua infraestrutura de TI funcione de forma otimizada e esteja sempre protegida contra falhas e ameaças de segurança." }
                ]}
            />


            <Footer />

            {/* Conteúdo adicional da página     */}
        </div>
    );
}

export default ServicoSingleB;
