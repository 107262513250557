import React, { useState } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import './stylesComponent/ServicoVideoDescricao.css'; // Importando o arquivo de estilos

const ServicoVideoDescricao = ({ videoUrl, titulo, descricao, imagem }) => {
    const [isOpen, setIsOpen] = useState(false); // Estado para controlar se o modal está aberto

    // Função para abrir o modal
    const openModal = () => {
        setIsOpen(true);
    };

    // Função para fechar o modal
    const closeModal = () => {
        setIsOpen(false);
    };

    return (
        <div className="servico-video-container">
        <div className="row">
            <div className="col-12">
                <div className="video-container">

                    <div className="video-overlay" onClick={openModal}>
                        <img src={imagem} alt="Vídeo" className="video-thumbnail" />
                        <div className="play-icon text-primary"> {/* Adicionando evento de clique */}
                            <FontAwesomeIcon icon={faPlayCircle} />
                        </div>
                    </div>

                    <h3 className="video-titulo">{titulo}</h3>
                    <p className="video-descricao">{descricao}</p>
                    <div className="bar-title "></div>

                    <Modal
                        isOpen={isOpen}
                        onRequestClose={closeModal}
                        contentLabel="Modal de Vídeo"
                        className="custom-modal"
                        overlayClassName="custom-overlay"
                    >
                        <FontAwesomeIcon icon={faTimes} className="close-modal" onClick={closeModal} />
                        <div className="video-container">
                            <iframe
                                title="Vídeo do YouTube"
                                width="560"
                                height="315"
                                src={videoUrl}
                                frameBorder="0"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </Modal>
                </div>
            </div>
        </div>
        </div>
    );
}

export default ServicoVideoDescricao;
