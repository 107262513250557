import React from 'react';
import { Link } from 'react-router-dom';
import './stylesComponent/ArtigosDestaque.css'; // Importando o arquivo de estilos

const ArtigoItems = () => {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth" // Opção para um scroll suave
        });
    };

    return (
        <section className="artigos-destaque">
                      
            {/* Colunas com os artigos */}
            <div className="row">
               
                <div className="col-md-6 col-sm-10 mb-4">
                    <div className="artigo">
                        <img src="/images/blog/ARTG01 (2).png" alt="Artigo 1" className="artigo-imagem" />
                        <span className="artigo-data">20 de Abril de 2024</span>
                        <h3 className="artigo-titulo"><Link to="/artigo1" onClick={scrollToTop}>Como optimizar sua infraestrutura de rede para melhorar o desempenho e segurança</Link></h3>
                        <p className="artigo-descricao">Descubra como otimizar sua infraestrutura de rede para melhorar tanto o desempenho quanto a segurança da sua empresa. Conheça estratégias eficazes para maximizar a eficiência da rede, garantir uma conectividade confiável e proteger os dados contra ameaças cibernéticas 2024. </p>
                        <Link to="/artigo1" onClick={scrollToTop} className="artigo-link">Ler mais</Link>
                    </div>
                </div>
                <div className="col-md-6 col-sm-10 mb-4">
                    <div className="artigo">
                        <img src="/images/blog/ARTG04 (2).png" alt="Artigo 1" className="artigo-imagem" />
                        <span className="artigo-data">21 de Abril de 2024</span>
                        <h3 className="artigo-titulo"><Link to="/artigo2" onClick={scrollToTop}>Estrategias eficientes para engajar sua audiência online</Link></h3>
                        <p className="artigo-descricao">Descubra as estratégias mais eficientes para envolver e manter sua audiência online. Desde o uso de conteúdo interativo até a criação de comunidades engajadas, explore maneiras de fortalecer o relacionamento com seus seguidores e impulsionar o crescimento do seu negócio online.</p>
                        <Link to="/artigo2" onClick={scrollToTop} className="artigo-link">Ler mais</Link>
                    </div>
                </div>
                <div className="col-md-6 col-sm-10 mb-4">
                    <div className="artigo">
                        <img src="/images/blog/ARTG03 (2).png" alt="Artigo 1" className="artigo-imagem" />
                        <span className="artigo-data">22 de Abril de 2024</span>
                        <h3 className="artigo-titulo"><Link to="/artigo3" onClick={scrollToTop}>Transformando dados em insights valiosos.</Link></h3>
                        <p className="artigo-descricao">Descubra como transformar dados em insights valiosos para impulsionar o crescimento e a eficácia dos negócios. Explore estratégias de análise de dados, visualização de informações e tomada de decisões informadas para extrair o máximo valor dos seus dados corporativos.</p>
                        <Link to="/artigo3" onClick={scrollToTop} className="artigo-link">Ler mais</Link>
                    </div>
                </div>

                <div className="col-md-6 col-sm-10 mb-4">
                    <div className="artigo">
                        <img src="/images/blog/ARTG02 (2).png" alt="Artigo 1" className="artigo-imagem" />
                        <span className="artigo-data">23 de Abril de 2024</span>
                        <h3 className="artigo-titulo"><Link to="/artigo4" onClick={scrollToTop}>Importância do treinamento em TI</Link></h3>
                        <p className="artigo-descricao">Explore a importância vital do treinamento em Tecnologia da Informação (TI) para empresas modernas. Descubra como investir no desenvolvimento das habilidades técnicas da sua equipe pode impulsionar a inovação, a eficiência e o sucesso do seu negócio.</p>
                        <Link to="/artigo4" onClick={scrollToTop} className="artigo-link">Ler mais</Link>
                    </div>
                </div>
            
            </div>
        </section>
    );
}

export default ArtigoItems;
