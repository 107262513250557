import React from 'react';
import './stylesComponent/FaqsDestaque.css'; // Importando o arquivo de estilos

const TextoImagemColunasVB = ({ imagem, listaItens }) => {
    return (
        <div className="texto-video-destaque my-4">
            {/* Coluna com o Accordion das perguntas */}
            <div className="row texto-coluna-destaque">
                {/* Iterando sobre as listas de imagens e itens */}
              
                    <div className="col-md-6 mb-4">
                        <img src={imagem} alt={`Imagem`} className="faqs-thumbnail" />
                    </div> 
               
                <div className="col-md-6 mb-4">
                    {/* Iterando sobre a lista de itens */}
                    {listaItens.map((item, index) => (
                        <div key={index}>
                            <h3 className="video-titulo">{item.titulo}</h3>
                            <p className="video-descricao">{item.descricao}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default TextoImagemColunasVB;
