// src/pages/Index.jsx

/* eslint-disable no-dupe-keys */
import React, { useState, useEffect } from "react";
import AuthUser from '../components/AuthUser';
import Footer from '../components/Footer';
import Banner from '../components/Banner';
import Testemunhos1 from '../components/Testemunhos1';
import MenuGeral from '../components/Layout/MenuGeral';
import TreinamentosItem from '../components/TreinamentosItem';

const Treinamentos = () => {

    // eslint-disable-next-line no-unused-vars
    const { http } = AuthUser();

    useEffect(() => {

        const formData = new FormData();
        formData.append('pagina', "Treinamentos");

        http.post('/contar-pagina-visitada', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });

    }, []);

    return (
        <div>
            <MenuGeral />

            <Banner
                backgroundImage="/images/page-title/BNNRP4.png"
                path="Institucional"
                currentPage="Treinamentos"
            />

            <TreinamentosItem />

            <Footer />

            {/* Conteúdo adicional da página     */}
        </div>
    );
}

export default Treinamentos;
