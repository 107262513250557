import React, { useState, useEffect } from "react";
import './stylesComponent/NewsLetter1.css';

import AuthUser from './AuthUser';
import Spinner from './Spinner.js';

import { SnackbarProvider, enqueueSnackbar, useSnackbar } from 'notistack';

const NewsLetter1 = () => {
    const { enqueueSnackbar } = useSnackbar(); // Importe useSnackbar para usar enqueueSnackbar

    // eslint-disable-next-line no-unused-vars
    const { http } = AuthUser();

    // State to track whether the "checkgeral" checkbox is checked
    const [loading, setLoading] = useState(false);

    const [email, setEmail] = useState('');

    const handleContactar = async () => {
        try {


            if (!isEmailValid(email)) {
                enqueueSnackbar('Por favor, forneça um e-mail válido.', { variant: 'error' });
                return;
            }


            setLoading(true);

            // Envie uma solicitação para pesquisar usuario
            const formData = new FormData();
            formData.append('titulo', "Subscrição para receber novidades");
            formData.append('descricao', "Este é um e-mail recebido do formulario de subscrição pelo site da equalizador");
            formData.append('email', email);
            formData.append('telefone', 'Não definido');
            formData.append('nome', 'Não definido');

            const response = await http.post('/contactar-equalizador', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });


            enqueueSnackbar('Recebemos o seu e-mail, muito obrigado.', { variant: 'success' });

            setEmail('');

            console.log('Enviado com sucesso');

            setLoading(false);




        } catch (error) {
            // Exiba uma mensagem de erro se ocorrer um problema
            /*toast.error('Ocorreu um erro pesquisar. Por favor, tente novamente mais tarde.', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });*/

            enqueueSnackbar('Ocorreu um erro pesquisar. Por favor, tente novamente mais tarde.', { variant: 'error' });

            setLoading(false);

        }
    };

    const isEmailValid = (email) => {
        // Expressão regular para validar e-mail
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    if (loading) return (<><Spinner></Spinner></>); else return (
        <>
        <section className="newsletter1" style={{ backgroundImage: `url(/images/about/about-2.png)` }}>
            <div className="newsletter1-bg"  style={{ background: 'rgba(0, 156, 249, 0.4)' }}>
                {/* Imagem de capa com efeito parallax */}
            </div>
            <div className="newsletter1-content">
                <h2 className="newsletter1-title">Encontre a melhor solução para o seu négocio & Atualize-se com as nossas novidades</h2>
                <p className="newsletter1-description">Mantenha-se atualizado com os nossos serviços e treinamentos</p>
                <div className="newsletter1-form">
                    <input type="text" placeholder="Seu endereço de e-mail" value={email}
                                            onChange={(e) => setEmail(e.target.value)} />
                    <button onClick={() => handleContactar()}>Inscrever-se</button>
                </div>
            </div>
        </section>
        </>
    );
}


export default function WrappedFooter() {
    return (
        <SnackbarProvider>
            <NewsLetter1 />
        </SnackbarProvider>
    );
}

