import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptopCode, faServer, faChartPie, faChalkboardTeacher } from '@fortawesome/free-solid-svg-icons';
import './stylesComponent/FeatureServicos.css'; // Importando o arquivo de estilos
import { Link } from 'react-router-dom';

const FeatureServicos = () => {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth" // Opção para um scroll suave
        });
    };

    return (
        <section className="feature-servicos">
            <div className="">
                <div className="row mb-1">
                    <div className="col-md-12 text-center d-flex flex-column align-items-center">
                        <h2 className="titulo">Nossos serviços</h2>
                        <p className="descricao">Desbloqueie todo o potencial do seu negócio com os nossos serviços de IT.</p>
                        <div className="bar-title "></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6 mb-4 coluna-servico">
                        <div className="text-center">
                            <div className="circle mb-3">
                                <FontAwesomeIcon icon={faLaptopCode} className="icon" />
                            </div>
                            <h3 className="servico-titulo">Desenvolvimento Web & Mobile</h3>
                            <p className="servico-descricao">Desenvolvemos sistemas inovadores, sites atraentes e aplicativos mobile envolventes, moldando soluções digitais sob medida para o sucesso de nossos clientes.</p>
                            <Link to="/servico-single" onClick={scrollToTop}><span>Ler mais </span></Link>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-4 coluna-servico">
                        <div className="text-center">
                            <div className="circle mb-3">
                                <FontAwesomeIcon icon={faServer} className="icon" />
                            </div>
                            <h3 className="servico-titulo mb-2">Infraestrutura e Sistemas de TI</h3>
                            <p className="servico-descricao">Garantimos operações eficientes por meio da implementação e manutenção de infraestruturas e sistemas, proporcionando estabilidade e segurança para sua jornada digital.</p>
                            <Link to="/servico-singleB" onClick={scrollToTop}><span>Ler mais </span></Link>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-4 coluna-servico">
                        <div className="text-center">
                            <div className="circle mb-3">
                                <FontAwesomeIcon icon={faChartPie} className="icon" />
                            </div>
                            <h3 className="servico-titulo mb-2">Análise & Tratamento de dados (BI)</h3>
                            <p className="servico-descricao">Potencializamos sua tomada de decisão com Business Intelligence, usando o Power BI para transformar dados em insights estratégicos e impulsionar resultados empresariais.</p>
                            <Link to="/servico-singleC" onClick={scrollToTop}><span>Ler mais </span></Link>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-4 coluna-servico">
                        <div className="text-center">
                            <div className="circle mb-3">
                                <FontAwesomeIcon icon={faChalkboardTeacher} className="icon" />
                            </div>
                            <h3 className="servico-titulo mb-2">Consultoria e Treinamentos em TI</h3>
                            <p className="servico-descricao">Desenvolvendo talentos, elevando profissionais e moldando futuros profissionais, para o mercado de TI.</p>
                            <Link to="/" onClick={scrollToTop}><span>Ler mais </span></Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default FeatureServicos;
