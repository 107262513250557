import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faUser, faBars } from '@fortawesome/free-solid-svg-icons';
import './../stylesComponent/menuGeral.css';
import { Link } from 'react-router-dom';

const MenuGeral = () => {

    const [menuOpen, setMenuOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [isFixed, setIsFixed] = useState(false);

    const [activeMenuItem, setActiveMenuItem] = useState(-1); // Inicialmente nenhum item de menu está ativo


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 990);
            console.log('Resize: ', window.innerWidth < 990);
        };

        const handleScroll = () => {
            setIsFixed(window.scrollY > 0);
            console.log('Scroll: ', window.scrollY > 0);
        };


        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll);

        handleResize(); // Executa ao carregar a página para definir o estado inicial

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);
        };

    }, []);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    return (
        <div className={`ancora menu-superior ${isFixed ? 'fixed' : ''}`}>
            <div className="logo"> {/* Lado esquerdo para o logotipo */}
                <Link to="/"><img src="/images/logo/equalizador.png" alt="Logo" /></Link>
            </div>

            <div className={`menu-items ${menuOpen ? 'openMenu' : ''}`}>
                <ul className="ul_primary">
                    <li><Link to="/">Início</Link></li>
                    <li>
                        <Link>Institucional</Link>
                        <ul className="dropdown">
                            <li><Link to="/sobre-nos" onClick={scrollToTop}>Sobre nós</Link></li>
                            <li><Link to="/faqs" onClick={scrollToTop}>FAQs</Link></li>
                            <li><Link to="/contactos" onClick={scrollToTop}>Contacto</Link></li>
                        </ul>
                    </li>
                    <li><Link to="/servicos" onClick={scrollToTop}>Serviços</Link></li>
                    <li><Link to="/projetos" onClick={scrollToTop}>Projetos</Link></li>
                    <li><Link to="/treinamentos" onClick={scrollToTop}>Treinamentos</Link></li>
                    <li><Link to="/artigos" onClick={scrollToTop}>Artigos</Link></li>
                    <li className='li_entrar'><Link to="/contactos" onClick={scrollToTop}><FontAwesomeIcon icon={faUser} />
                        &nbsp; Contactar</Link></li>
                </ul>
            </div>

            <div className="icons"> {/* Ícones de pesquisa e botão de login */}
                {/* <div className="search-icon">
                    <FontAwesomeIcon icon={faSearch} />
                </div> */}
                <button className="login-button" ><Link to="/contactos" className="text-white"><FontAwesomeIcon icon={faUser} />
                    &nbsp; Contactar</Link>
                </button>
            </div>

            <div className="hamburger-menu text-dark" onClick={toggleMenu}>
                <FontAwesomeIcon icon={faBars} />
            </div>

        </div>
    );

}

export default MenuGeral;
