import React from 'react';
import './stylesComponent/Banner.css'; // Importe o arquivo de estilos

const Banner = ({ backgroundImage, path, currentPage }) => {
    return (
        <div className="banner" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className="overlay"></div> {/* Overlay para tornar o texto mais nítido */}
            <div className="banner-content d-flex flex-column align-items-center">
                <h1 className="banner-title">{currentPage}</h1>
                <ul className="pagination">
                    <li className={`page-item ${path === '/page1' ? 'active' : ''}`}>
                        <a className="page-link" href="/">{path}</a>
                    </li>
                    <li className={`page-item ${path === '/page2' ? 'active' : ''}`}>
                        <a className="page-link" href="/">{currentPage}</a>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Banner;
