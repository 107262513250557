import React from 'react';
import './stylesComponent/ProjetosDestaque.css';

const ProjetosDestaque = () => {
    return (
        <section className="projetos-destaque">
            <div className="">
                <div className='row'>

                    <div className="col-md-12 mb-4 info-content text-center d-flex flex-column align-items-center">
                        <h2 className="titulo">Projetos desafiadores que nos elevaram</h2>
                        <p className="descricao">Os desafios são apenas oportunidades disfarçadas. Aceite o desafio!</p>
                        <div className="bar-title "></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-4">
                        <div className="destaque-item">
                            <div className="destaque-imagem">
                                <img src="/images/portfolio/portfolio1.png" alt="Vídeo" className="video-thumbnail" />
                            </div>
                            <div className="destaque-info">
                                <a href="#">
                                    <h3>Sistema Gestão De Projeto</h3>
                                    <span>Desenvolvimento WEB</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-4">
                        <div className="destaque-item">
                            <div className="destaque-imagem">
                                <img src="/images/portfolio/portfolio2.png" alt="Vídeo" className="video-thumbnail" />
                            </div>
                            <div className="destaque-info">
                                <a href="#">
                                    <h3>Site KAAUTO</h3>
                                    <span>Website</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-4">
                        <div className="destaque-item">
                            <div className="destaque-imagem">
                                <img src="/images/portfolio/portfolio3.png" alt="Vídeo" className="video-thumbnail" />
                            </div>
                            <div className="destaque-info">
                                <a href="#">
                                    <h3>Candidatura WEB</h3>
                                    <span>Website & API</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    {/* Outras colunas aqui */}
                </div>

                <div className="row mb-5">
                    <div className="col-lg-4 col-md-6 mb-4">
                        <div className="destaque-item">
                            <div className="destaque-imagem">
                                <img src="/images/portfolio/portfolio4.png" alt="Vídeo" className="video-thumbnail" />
                            </div>
                            <div className="destaque-info">
                                <a href="#">
                                    <h3>Angola Telecom Site</h3>
                                    <span>Website</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-4">
                        <div className="destaque-item">
                            <div className="destaque-imagem">
                                <img src="/images/portfolio/portfolio5.png" alt="Vídeo" className="video-thumbnail" />
                            </div>
                            <div className="destaque-info">
                                <a href="#">
                                    <h3>App Arte & Fé</h3>
                                    <span>Mobile App</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-4">
                        <div className="destaque-item">
                            <div className="destaque-imagem">
                                <img src="/images/portfolio/portfolio6.png" alt="Vídeo" className="video-thumbnail" />
                            </div>
                            <div className="destaque-info">
                                <a href="#">
                                    <h3>Arte & Fé</h3>
                                    <span>Website</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    {/* Outras colunas aqui */}
                </div>
                {/* Outras rows aqui */}
            </div>
        </section>
    );
}

export default ProjetosDestaque;
