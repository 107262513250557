// src/App.js

import React from 'react';
import AppRouter from './Router';
// Exemplo de importação de CSS em um componente React
// No seu componente MenuSuperior.jsx
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/style.css';



const App = () => {
  return (
    <div className="App">
      <AppRouter />
    </div>
  );
};

export default App;
