import React from 'react';
import { Link } from 'react-router-dom';
import './stylesComponent/ServicosItem.css'; // Importando o arquivo de estilos

const ServicosItem = () => {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth" // Opção para um scroll suave
        });
    };

    return (
        <section className="servicos-destaque">
            {/* Título e Descrição centralizados */}
            <div className="text-center mb-2 d-flex flex-column align-items-center">
                <h2 className="titulo">Serviços Em Destaque</h2>
                <p className="descricao text-center text-dark">Desbloqueie todo o potencial do seu negócio com os nossos serviços de IT.</p>
                <div className="bar-title "></div>
            </div>

            {/* Colunas com os serviços */}
            <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <div className="servico">
                        <div className="imagem">
                            <Link to="/servico-single" onClick={scrollToTop}><img src="/images/agency-services/agn-service1.png" alt="titulo 1" /></Link>
                        </div>
                        <div className="conteudo">
                            <Link to="/servico-single" onClick={scrollToTop} ><h3 className="titulo">Desenvolvimento Web e Mobile, sites, sistemas e Apps</h3></Link>
                            <p className="descricao">Desenvolvimento</p>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <div className="servico">
                        <div className="imagem">
                            <Link to="/servico-singleB" onClick={scrollToTop}> <img src="/images/agency-services/agn-service2.png" alt="titulo 2" /></Link>
                        </div>
                        <div className="conteudo">
                            <Link to="/servico-singleB" onClick={scrollToTop}><h3 className="titulo">Implementação de infraestrtura e sistemas</h3></Link>
                            <p className="descricao">Network</p>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <div className="servico">
                        <div className="imagem">
                            <Link to="/servico-singleC" onClick={scrollToTop}><img src="/images/agency-services/agn-service3.png" alt="titulo 3" /></Link>
                        </div>
                        <div className="conteudo">
                            <Link to="/servico-singleC" onClick={scrollToTop}><h3 className="titulo">Business Intelligence e Analise de Dados</h3></Link>
                            <p className="descricao">Tratamento da informação</p>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
}

export default ServicosItem;
