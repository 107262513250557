// src/pages/Index.jsx
/* eslint-disable no-dupe-keys */
import React, { useState, useEffect } from "react";
import AuthUser from '../components/AuthUser';
import Footer from '../components/Footer';
import Banner from '../components/Banner';
import MenuGeral from '../components/Layout/MenuGeral';
import ArtigoItems from '../components/ArtigoItems';
import ArtigoSidebar from '../components/ArtigoSideBar';

const Artigos = () => {

    // eslint-disable-next-line no-unused-vars
    const { http } = AuthUser();

    useEffect(() => {

        const formData = new FormData();
        formData.append('pagina', "artigos");

        http.post('/contar-pagina-visitada', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
    
      }, []);

    return (
        <div>
            <MenuGeral />

            <Banner
                backgroundImage="/images/page-title/page-title-bg-4.png"
                path="Institucional"
                currentPage="Artigos"
            />


            <div className="row">
                <div className="col-lg-8 col-md-12">
                    <ArtigoItems />
                </div>
                <div className="col-lg-4 col-md-12">
                    <ArtigoSidebar />
                </div>
            </div>


            <Footer />

            {/* Conteúdo adicional da página     */}
        </div>
    );
}

export default Artigos;
